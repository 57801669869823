import React from 'react'
import SEO from "../components/atoms/seo"

import Layout from '../components/organisms/layout'
import Heading from '../components/molecules/heading'
import BodyCopy from '../components/atoms/body-copy'
import Lists from '../components/atoms/lists'


const MarketingPartnersPage = () => (
  <Layout hideSecureForm>
    <SEO title='Marketing Partners' />
    <Heading 
        title='Marketing Partners'
        weight='light'
    />
    <BodyCopy
      copy={
        "[--MARKETING-PARTNER-NAMES--]"
      }
    />

    
  </Layout>
)

export default MarketingPartnersPage
